export const GoogleLogo = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.2841 10.7333C22.2841 9.86999 22.214 9.23999 22.0624 8.58667H12.2041V12.4833H17.9907C17.8741 13.4517 17.2441 14.91 15.8441 15.8899L15.8245 16.0204L18.9415 18.4351L19.1574 18.4567C21.1407 16.625 22.2841 13.93 22.2841 10.7333Z"
				fill="#4285F4"
			/>
			<path
				d="M12.2042 21C15.0392 21 17.4192 20.0666 19.1576 18.4567L15.8442 15.8899C14.9576 16.5083 13.7675 16.9399 12.2042 16.9399C9.4276 16.9399 7.07095 15.1083 6.23087 12.5767L6.10773 12.5871L2.8666 15.0955L2.82422 15.2133C4.55087 18.6433 8.09754 21 12.2042 21Z"
				fill="#34A853"
			/>
			<path
				d="M6.23072 12.5767C6.00906 11.9234 5.88078 11.2233 5.88078 10.5C5.88078 9.77667 6.00906 9.0767 6.21906 8.42337L6.21319 8.28423L2.93145 5.7356L2.82407 5.78667C2.11244 7.21002 1.7041 8.80838 1.7041 10.5C1.7041 12.1917 2.11244 13.79 2.82407 15.2133L6.23072 12.5767Z"
				fill="#FBBC05"
			/>
			<path
				d="M12.2042 4.05997C14.1759 4.05997 15.5059 4.91163 16.2642 5.62335L19.2275 2.73C17.4076 1.03834 15.0392 0 12.2042 0C8.09754 0 4.55087 2.35665 2.82422 5.78662L6.21921 8.42332C7.07095 5.89166 9.4276 4.05997 12.2042 4.05997Z"
				fill="#EB4335"
			/>
		</svg>
	)
}
